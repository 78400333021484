import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  alertVisibility: boolean;
  alertMessage: string;
  popoverState: {
    visibility: boolean;
    title: string;
    subTitle?: string;
    icon?: any;
    iconColor?: string;
    primaryButtonText: string;
    primaryButtonColor: string;
    secondaryButtonText: string;
    onClose: () => void;
    primaryButtonAction: () => void;
    secondaryButtonAction: () => void;
  },
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChangePasswordController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  token: string = "";
  changePasswordCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      alertVisibility: false,
      alertMessage: "",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      popoverState: {
        visibility: false,
        title: "",
        subTitle: "",
        primaryButtonText: "",
        primaryButtonColor: "",
        secondaryButtonText: "",
        onClose: () => this.closePopover(),
        primaryButtonAction: () => {},
        secondaryButtonAction: () => {}
      },
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start

    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const isSessionResponseMessage = getName(MessageEnum.SessionResponseMessage) === message.id;
    const isRestAPIResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;

    if (isSessionResponseMessage) {
      this.handleSessionResponseChangePassword(message);
    }

    if (isRestAPIResponseMessage) {
      this.handleAPIResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  private handleSessionResponseChangePassword(message: Message) {
    const sessionToken = message.getData(getName(MessageEnum.SessionResponseToken));
    if (!sessionToken || sessionToken === "null") {
      this.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
    } else {
      this.token = sessionToken
    }

    const testToken = window.localStorage.getItem("testToken");

    if (testToken) {
      this.token = testToken;
    }

    runEngine.debugLog("TOKEN1", this.token);
  }

  private handleAPIResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    this.setState({
      loading: false
    })

    if (responseJson && !responseJson.errors) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (apiRequestCallId === this.changePasswordCallId) {
        this.setState({
          popoverState: {
            ...this.state.popoverState,
            visibility: true,
            icon: <LockOutlinedIcon sx={{fontSize: 24, color: "#8861A9"}} />,
            iconColor: "#8861A9",
            title: "Password change successful!",
            primaryButtonText: "Done",
            primaryButtonColor: "#8861A9",
            primaryButtonAction: () => this.navigateToProfile(),
          }
        })
      }
    } else {
      this.showAlert(
        "Error",
        responseJson.errors[0].password || "Something went wrong!"
      )
    }
  }

  showAlert(alertType: string, alertMessage: string) {
    window.scrollTo({
      top: 0
    })
    
    this.setState({
      alertVisibility: true,
      alertMessage: alertMessage
    })
  }

  closePopover = () => {
    this.setState({
      popoverState: {
        ...this.state.popoverState,
        visibility: false,
      }
    })
  }

  btnGoBack = {
    onPress: () => this.props.navigation.goBack()
  }

  popoverProps = {
    onClose: () =>  this.state.popoverState.onClose(),
    primaryButtonAction: () =>  this.state.popoverState.primaryButtonAction(),
    secondaryButtonAction: () => this.state.popoverState.secondaryButtonAction(),
  };

  txtInputOldPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ oldPassword: text });

      //@ts-ignore
      this.txtInputOldPasswordProps.value = text;
    },
  };

  txtInputNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ newPassword: text });

      //@ts-ignore
      this.txtInputNewPasswordProps.value = text;
    },
  };

  txtInputConfirmNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ confirmNewPassword: text });

      //@ts-ignore
      this.txtInputConfirmNewPasswordProps.value = text;
    },
  };

  navigateToProfile = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationUserProfileMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  changePassword = (): boolean => {
    if (
      this.state.oldPassword.length === 0 ||
      this.state.newPassword.length === 0 ||
      this.state.confirmNewPassword.length === 0
    ) {
      this.showAlert("Error", configJSON.errorBlankField);
      return false;
    }

    if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.showAlert("Error", "New password not matching");
      return false;
    }

    this.setState({
      alertVisibility: false,
      loading: true
    })
    
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.changePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.changePasswordEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        password: {
          old_password: this.state.oldPassword,
          new_password: this.state.newPassword
        }
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  // Customizable Area End
}
