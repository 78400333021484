import { Typography, Button } from "@builder/component-library";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Platform, StyleSheet, Switch, Text, TouchableOpacity, View } from "react-native";
import CloseIcon from '@mui/icons-material/Close';
import ReportProblemIcon from '@mui/icons-material/ReportProblemOutlined';
import LocationIcon from '@mui/icons-material/PlaceOutlined';
import StarIcon from '@mui/icons-material/StarOutlined';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';

const icons = {
    ALERT: ReportProblemIcon,
    LOCATION: LocationIcon,
}

type MyProps = {
  testID: string;
  visible: boolean;
  businessImage: string;
  businessTypeImage: string;
  businessName: string;
  businessRating: number;
  closesAt: string;
  onClose: () => void;
  onViewBusinessProfile: () => void;
};
type MyState = {  };

export default class BusinessPreview extends Component<MyProps, MyState> {
  static propTypes = {
    testID: PropTypes.string,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      
    };
  }

  render() {
    const { testID } = this.props;

    if (!this.props.visible) {
        return null
    }


    return (
        <View testID={testID} style={styles.screen}>
            <View style={styles.container}>
                <TouchableOpacity onPress={this.props.onClose} style={styles.closeButton}>
                    <CloseIcon sx={{ fontSize: 24, color: "#0F172A" }} />
                </TouchableOpacity>
                
                <img style={styles.businessImage} src={this.props.businessImage} />

                <View style={styles.businessInfo}>
                    <img style={styles.businessTypeImage} src={this.props.businessTypeImage} />
                    <View>
                        <Typography variant="2xl" style={styles.title}>{this.props.businessName}</Typography>
                        <View style={styles.ratingContainer}>
                            <Typography variant="base" style={styles.subTitle}>
                                {this.props.businessRating}
                            </Typography>
                            <View style={styles.rating}>
                                {Array.from({ length: 5 }).map((_, index) => {
                                    return (
                                        <StarIcon key={index} sx={{ fontSize: 16, color: index >= this.props.businessRating ? '#E2E8F0' : '#FCD34D' }} />
                                    )
                                })}
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.businessHoursContainer}>
                    <TimeIcon sx={{ fontSize: 20, color: "#8861A9" }} />
                    <Typography variant="base" style={styles.openText}>Open</Typography>
                    <Typography variant="base" style={styles.businessHoursText}> • Closes at {this.props.closesAt}</Typography>                                
                </View>


                <Button
                    style={styles.button}
                    textStyle={{ color: "#9BCE7B" }}
                    text="View business profile"
                    onPress={this.props.onViewBusinessProfile}
                />  
            </View>
        </View>
    )
  }
}

const styles = StyleSheet.create({
    screen: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
    },
    container: {
        width: "100%",
        maxWidth: 410,
        borderRadius: 8,
        paddingVertical: 16,
        paddingHorizontal: 24,
        backgroundColor: "#9BCE7B",
        zIndex: 2,
    },
    closeButton: {
        alignSelf: "flex-end",
        marginBottom: 20,
    },
    iconContainer: {
        width: 56,
        height: 56,
        borderRadius: 999,
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 16
    }, 
    textFont: {
        fontFamily: "Inter",
    },
    businessInfo: {
        flexDirection: "row"
    },
    businessImage: {
        width: "100%",
        height: 145,
        resizeMode: "cover",
        borderRadius: 16,
        marginBottom: 16,
    },
    businessTypeImage: {
        width: 56,
        height: 56,
        marginRight: 16
    },
    title: {
        fontFamily: "Inter",
        fontWeight: "700", 
        color: "#0F172A",
        marginBottom: 8
    },
    subTitle: {
        fontFamily: "Inter",
        fontWeight: "700", 
        color: "#0F172A",
    },
    secondaryBackground: {
        marginTop: 24,
        backgroundColor: 'transparent'
    },
    ratingContainer: {
        flexDirection: "row"
    },
    rating: {
        flexDirection: "row",
        marginLeft: 8
    },
    businessHoursContainer: {
        flexDirection: 'row',
        marginVertical: 16
    },
    openText: {
        fontFamily: "Inter",
        fontWeight: "400", 
        color: "#059669",
        marginLeft: 16,
        marginRight: 4,
    },
    closeText: {
        fontFamily: "Inter",
        fontWeight: "400", 
        color: "#DC2626",
        marginRight: 4,
    },
    businessHoursText: {
        fontFamily: "Inter",
        fontWeight: "400", 
        color: "#0F172A",
    },
    button: {
        backgroundColor: "#8861A9"
    }
})