import React from "react";

// Customizable Area Start
import { Input, Button, Typography, Checkbox } from '@builder/component-library';

import { View, StyleSheet, Image, Text, TouchableOpacity, ScrollView, Platform } from "react-native";
import { imgVisbility, imgVisbilityOff, imgCorrect, imgWrong, logo } from "./assets";

// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSelectUserType = () => (
    <View style={styles.choseUserTypeContainer}>
      <View style={styles.header}>
        <Image style={styles.imageLogoImage} source={{ uri: logo }} />
        <Text style={styles.textWelcomeLabel}>
          {this.welcomeLabel}
        </Text>
      </View>

      <View style={styles.w100}>
        <TouchableOpacity
          testID={"btnIndivudal"}
          style={ this.state.selectedAccountType === 'User' ? styles.buttonRadioSelected : styles.buttonRadio}
          {...this.btnSelectedIndividual}
        >
          <View style={this.state.selectedAccountType === 'User' ? styles.radioSelected : styles.radio} />

          <Typography style={styles.radioText}>I’m a supporter of small businesses</Typography>
        </TouchableOpacity>

        <TouchableOpacity
          testID={"btnBusiness"}
          style={ this.state.selectedAccountType === 'Business' ? styles.buttonRadioSelected : styles.buttonRadio}
          {...this.btnSelectedBusiness}
        >
          <View style={this.state.selectedAccountType === 'Business' ? styles.radioSelected : styles.radio} />
          <Typography style={styles.radioText}>I’m a small business owner</Typography>
        </TouchableOpacity>
      </View>

      <View
        style={styles.w100}
      >
        <Button
          testID="btnSelectedAccountType"
          textStyle={styles.buttonBackgroundDarkPurpleText}
          style={styles.buttonBackgroundDarkPurple}
          {...this.btnSelectedAccountType}
          text="Continue"
        />
      </View>
    </View>
  );

  renderSignUpForm = () => (
    <View style={styles.formContainer}>
      <Typography variant="2xl" style={styles.title}>Sign up</Typography>

      {
        this.state.alertVisibility && (
          <View style={styles.alertContainer}>
            <Typography style={styles.alertText}>{this.state.alertMessage}</Typography>
          </View>
        )
      }

      <View style={styles.formWrapper}>
      {
        this.state.accountType === 'User' && (
          <View style={styles.field}>
            <Typography variant="sm" style={styles.label}>First name</Typography>
              <Input
                containerStyle={styles.input}
                testID="txtInputFirstName"
                placeholder="First name"
                placeholderTextColor={"#94A3B8"}
                value={this.state.firstName}
                {...this.txtInputFirstNamePrpos} 
              />
          </View>
        )
      }
      {
        this.state.accountType === 'User' && (
          <View style={styles.field}>
            <Typography variant="sm" style={styles.label}>Last name</Typography>
              <Input
                containerStyle={styles.input}
                testID="txtInputLastName"
                placeholder="Last Name"
                placeholderTextColor={"#94A3B8"}
                value={this.state.lastName}
                {...this.txtInputLastNamePrpos} 
              />
          </View>
        )
      }
      {
        this.state.accountType === "Business" && (
          <View style={styles.field}>
            <Typography variant="sm" style={styles.label}>Business name</Typography>
              <Input
                containerStyle={styles.input}
                testID="txtBusinessName"
                placeholder="Business Name"
                placeholderTextColor={"#94A3B8"}
                value={this.state.businessName}
                {...this.txtBusinessNameProps} 
              />
          </View>
        ) 
      }
      <View style={styles.field}>
        <Typography variant="sm" style={styles.label}>{this.state.accountType === "Business" ? "Business email" : "Email"}</Typography>
          <Input
            containerStyle={styles.input}
            testID="txtInputEmail"
            placeholder="Email"
            placeholderTextColor={"#94A3B8"}
            value={this.state.email}
            {...this.txtInputEmailWebPrpos} 
          />
      </View>
      {
        this.state.accountType === "Business" && (
          <View style={styles.field}>
            <Typography variant="sm" style={styles.label}>Business address</Typography>
              <Input
                containerStyle={styles.input}
                testID="txtBusinessAddress"
                placeholder="3, Red Wing Avenue, Buffalo, Minnesot..."
                placeholderTextColor={"#94A3B8"}
                value={this.state.businessAddress}
                {...this.txtBusinessAddressProps} 
              />
          </View>
        ) 
      }

      <View style={styles.field}>
        <Typography variant="sm" style={styles.label}>Password</Typography>
        <Input
          containerStyle={styles.input}
          testID="txtInputPassword"
          placeholder="Password"
          placeholderTextColor={"#94A3B8"}
          value={this.state.password}
          {...this.txtInputPasswordProps} 
          secureTextEntry={this.state.enablePasswordField}
          rightIcon={
            <Button
              testID="btnPasswordShowHide"
              aria-label="toggle password visibility"
              {...this.btnPasswordShowHideProps} 
              style={[styles.removeBackground]}
              icon={!this.state.enablePasswordField ? (
                <img src={imgVisbility} />
              ) : (
                <img src={imgVisbilityOff} />
              )}
            />
          }
        />
      </View>

      <View style={styles.validationContainer}>
        <View style={styles.validationWraper}>
          <img src={this.state.passwordValidations.uppercase ? imgCorrect : imgWrong} style={styles.validationImage} />
          <Typography variant="xs" style={styles.validationText}>{this.errorPasswordUppercase}</Typography>
        </View>

        <View style={styles.validationWraper}>
          <img src={this.state.passwordValidations.lowercase ? imgCorrect : imgWrong} style={styles.validationImage} />
          <Typography variant="xs" style={styles.validationText}>{this.errorPasswordLowercase}</Typography>
        </View>

        <View style={styles.validationWraper}>
          <img src={this.state.passwordValidations.number ? imgCorrect : imgWrong} style={styles.validationImage} />
          <Typography variant="xs" style={styles.validationText}>{this.errorPasswordNumber}</Typography>
        </View>

        <View style={styles.validationWraper}>
          <img src={this.state.passwordValidations.length ? imgCorrect : imgWrong} style={styles.validationImage} />
          <Typography variant="xs" style={styles.validationText}>{this.errorPasswordLength}</Typography>
        </View>
      </View>

      <View style={styles.acceptPrivacyAndTermsContainer}>
        <Checkbox
          testID={"btnAcceptPrivacyAndTerms"}
          style={styles.acceptPrivacyAndTermsCheckbox}
          textStyle={styles.acceptPrivacyAndTermsCheckboxText}
          title=""
          checked={this.state.acceptTermsAndConditions}
          {...this.btnAcceptPrivacyAndTermsProps}
        />
        <View style={styles.acceptPrivacyAndTermesTitleContainer}>
          <Typography variant="base" style={styles.acceptPrivacyAndTermesTitle}>I hereby consent to the</Typography>

          <TouchableOpacity
            testID={"btnLegalPrivacyPolicy"}
            style={styles.termsAndConditionAndPrivacyButton}
            {...this.btnLegalPrivacyPolicyProps}
          >
            <Typography variant="base" style={styles.acceptPrivacyAndTermesButton}>Privacy Policy</Typography>
          </TouchableOpacity>

          <Typography variant="base" style={styles.acceptPrivacyAndTermesTitle}>and</Typography>
          <TouchableOpacity
            testID={"btnLegalTermsAndCondition"}
            style={styles.termsAndConditionAndPrivacyButton}
            {...this.btnLegalTermsAndConditionProps}
          >
            <Typography variant="base" style={styles.acceptPrivacyAndTermesButton}>Terms and Conditions</Typography>
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={styles.signUpButtonContanier}
      >
        <Button
          testID="btnSignUp"
          textStyle={styles.signUpButtonText}
          style={styles.signUpButton}
          loading={this.state.loading}
          text={this.state.loading ? "" : "Sign up"}
          {...this.btnSignUpProps}
        />
      </View>
      <View style={styles.logInButtonContainer}>
        <Typography variant="base" style={styles.logInButtonText} component="span">
          Already have an account?
        </Typography>
        <Button
          testID={"btnLogIn"}
          style={styles.removeBackground}
          textStyle={styles.logInButtonTextHighlighted}
          onPress={() => this.goToLogin()}
          text="Log In"
        />
      </View>    
      </View>
    </View>
  )

  renderBusinessQuestions = () => (
    <View style={styles.businessQuestionsContainer}>
      {
        this.state.alertVisibility && (
          <View style={styles.alertContainer}>
            <Typography style={styles.alertText}>{this.state.alertMessage}</Typography>
          </View>
        )
      }

      <View style={styles.header}>
        <Image style={styles.imageLogoImage} source={{ uri: logo }} />
        <Text style={styles.textWelcomeLabel}>
          We need you to answer a few questions related to your business profile
        </Text>
      </View>

      <View style={styles.formWrapper}>
        {
          this.state.businessQuestionsList.map(question => {
            return (
              <View key={question.id} style={styles.businessQuestionField}>
                <Typography variant="sm" style={styles.label}>{question.question}</Typography>
                  <Input
                    containerStyle={styles.input}
                    testID={`txtInputQuestion-${question.id}`}
                    placeholderTextColor={"#94A3B8"}
                    value={this.state.businessAnswersList.find(anwser => anwser.question_id === question.id)?.answer}
                    onChangeText={(text) => this.handleUpdateBusinessAnwser(question.id, text)}
                  />
              </View>
            )
          })
        }
      </View>

      <View
        style={styles.w100}
      >
        <Button
          testID="btnSignUpBusiness"
          textStyle={styles.buttonBackgroundDarkPurpleText}
          style={styles.buttonBackgroundDarkPurple}
          loading={this.state.loading}
          text={this.state.loading ? "" : "Continue"}
          {...this.btnSignUpBusiness}
        />
      </View>
    </View>
  )

  renderReviewOnGoing = () => (
    <View style={styles.reviewOnGoingContainer}>
      <Typography testID="reviewOnGoingTitle" variant="xl" style={styles.reviewTitle}>{this.state.reviewOnGoingTitle}</Typography>
      <Typography testID="reviewOnGoingText" variant="lg" style={styles.reviewSubTitle}>{this.state.reviewOnGoingText}</Typography>

      <View style={styles.w100}>
        <Button
          testID="btnReviewOnGoing"
          textStyle={styles.buttonBackgroundDarkPurpleText}
          style={styles.buttonBackgroundDarkPurple}
          onPress={() => this.goToLogin()}
          text="Ok"
        />
      </View>

    </View>
  )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start 
      <div  style={styles.screen}>
        <ScrollView>
          <View style={styles.main}>
            {this.state.screenStep === "SelectUserType" && this.renderSelectUserType()}
            {this.state.screenStep === "SignUpFrom" && this.renderSignUpForm()}
            {this.state.screenStep === "BusinessQuestions" && this.renderBusinessQuestions()}
            {this.state.screenStep === "ReviewOnGoing" && this.renderReviewOnGoing()}
          </View>
        </ScrollView>
      </div>
      // Customizable Area End
    );
  }


  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  screen: {
    width: "100%", 
    minHeight: "100%", 
    backgroundColor: "#9BCE7B"
  },
  main: {
    flex: 1, 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    padding: 24, 
    width: "100%", 
    height: "100%",
    backgroundColor: "#9BCE7B"
  },
  w100: {
    width: "100%", 
  },
  choseUserTypeContainer: {
    flex: 1,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%", 
    maxWidth: 360,
    marginTop: 160,
  },
  businessQuestionsContainer: {
    flex: 1,
    height: "100%",
    display: "flex",
    alignItems: "center",
    width: "100%", 
    maxWidth: 360
  },
  reviewOnGoingContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    marginTop: 160,
    width: "100%", 
    maxWidth: 360
  },
  reviewTitle: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#0F172A",
    marginBottom: 8,
    textAlign: "center"
  },
  reviewSubTitle: {
    fontFamily: "Inter",
    fontWeight: "400", 
    color: "#0F172A",
    textAlign: "center"
  },
  formContainer: {
    display: "flex", 
    flexDirection: "column", 
    alignItems: "center", 
    width: "100%", 
    maxWidth: 360, 
    height: "100%"
  },
  alertContainer: {
    paddingVertical: 21, 
    paddingHorizontal: 16, 
    width: "100%", 
    display: "flex", 
    alignItems: "flex-start", 
    backgroundColor: "#FEE2E2", 
    borderRadius: 4, 
    borderLeftWidth: 4, 
    borderLeftColor: "#DC2626", 
    marginBottom: 48
  },
  alertText: {
    fontFamily: "Inter",
    color: "#DC2626", 
    fontSize: 12
  },
  businessQuestionField: {
    marginBottom: "24px"
  },
  field: {
    flex: 1, 
    marginBottom: "24px"
  },
  title: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#000000",
    marginBottom: 48
  },
  label: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#334155"
  },
  validationText: {
    fontFamily: "Inter",
    fontWeight: "400", 
    color: "#000"
  },
  acceptPrivacyAndTermsContainer: {
    display: "flex",
    flexDirection: "row", 
    alignItems: "flex-start", 
    marginTop: 48,
    width: "100%",
  },
  acceptPrivacyAndTermsCheckbox: {
    marginVertical: 0, 
    marginHorizontal: 0, 
    padding: 0,
    margin: 0,
  },
  acceptPrivacyAndTermsCheckboxText:{
    fontSize: 14, 
    color: "#0F172A",
    fontWeight: "400" 
  },
  acceptPrivacyAndTermesTitleContainer: {
    width: "100%",
    display: "flex", 
    flexDirection: "row", 
    alignItems: "flex-start", 
    justifyContent: "flex-start", 
    flexWrap: "wrap"
  },
  acceptPrivacyAndTermesTitle: { 
    fontFamily: "Inter", 
    fontSize: 14, 
    fontWeight: "400", 
    color: "#334155" 
  },
  acceptPrivacyAndTermesButton: {
    fontFamily: "Inter", 
    fontWeight: "700", 
    color: "#8861A9", 
    textDecorationLine: "underline"
  },
  signUpButtonContanier: {
    flex: 1
  },
  signUpButton: {
    backgroundColor: "#8861A9", 
    marginTop: "48px"
  },
  signUpButtonText: {
    color: "#FFFFFF"
  },
  logInButtonContainer: {
    display: "flex", 
    flexDirection: "row", 
    alignItems: "center", 
    justifyContent: "center", 
    marginTop: 24
  },
  logInButtonText: {
    fontFamily: "Inter", 
    color: "#0F172A"
  },
  logInButtonTextHighlighted: {
    fontFamily: "Inter", 
    fontSize: 16,
    fontWeight: "700", 
    color: "#8861A9"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 48
  },
  imageLogoImage: {
    width: "64px",
    height: "64px",
    resizeMode: "cover"
  },
  textWelcomeLabel: {
    fontSize: 20,
    fontWeight: "700",
    color: "#2A0066",
    marginTop: 32,
    textAlign: "center"
  },
  buttonRadio: {
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderColor: "#CBD5E1",
    borderRadius: 8,
    marginTop: 8,
    paddingVertical: 16,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center"
  },
  radio: {
    height: 20,
    width: 20,
    borderRadius: 12,
    borderWidth:  1,
    borderColor: '#2A0066',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonRadioSelected: {
    backgroundColor: "#E1CCFF",
    borderWidth: 1,
    borderColor: "#A666FF",
    borderRadius: 8,
    marginTop: 8,
    color: "#2A0066",
    paddingVertical: 16,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center"
  },
  radioSelected:{
    height: 20,
    width: 20,
    borderRadius: 12,
    borderWidth: 6,
    borderColor: '#2A0066',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioText: {
    fontFamily: "Inter",
    fontSize: 16, 
    fontWeight: "700", 
    color: "#2A0066",
    marginLeft: 20
  },
  buttonBackgroundDarkPurple: {
    backgroundColor: "#8861A9",
    marginTop: 48
  },
  buttonBackgroundDarkPurpleText: {
    color: "#FFFFFF"
  },
  validationContainer : {
    marginTop: 24,
  },
  validationWraper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8
  },
  validationImage: {
    marginRight: 4,
    width: 12,
    height: 12
  },
  textFont: {
    fontFamily: "Inter",
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    marginTop: 20
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  termsAndConditionAndPrivacyButton: {
    marginHorizontal: 4
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  input: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    ...Platform.select({
      web: {
        borderWidth: 0, 
        outlineStyle: "none"
      },
      default: {}
    })
  }
  // Customizable Area End
});
