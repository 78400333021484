import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  ScrollView,
  Text,
  View,
  ActivityIndicator,
  TouchableOpacity
} from "react-native";
import MarkdownPreview from "@uiw/react-markdown-preview";
import CloseIcon from '@mui/icons-material/Close';
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return this.state.isLoading ? (
      <View style={styles.spinnerContainer}>
        <ActivityIndicator color="#000" size={30} />
      </View>
    ) : (
      <View style={styles.main}>
        <View style={styles.overlay} />
        <View style={styles.container}>
          <View style={styles.header}>
            <View />
            <Text style={styles.termsCondsTitle}>{this.state.type === "TermsAndCondition" ? "Terms and Conditions" : "Privacy Policy"}</Text>
              
            <TouchableOpacity testID="btnCloseTermsConditions" onPress={this.hanleAceptoOrCancelTerms} style={styles.closeButton}>
              <CloseIcon sx={{ fontSize: 24, color: "#0F172A" }} />
            </TouchableOpacity>
          </View>
          <View style={{flex : 1 }}>
            <ScrollView
              contentInsetAdjustmentBehavior="automatic"
              style={styles.scrollView}
            >
              <MarkdownPreview  
                style={styles.description} 
                source={this.state.type === "TermsAndCondition" ? this.state.termsCondsDescription : this.state.privacyPolicyDescription}
              /> 
            </ScrollView> 
          </View>
          </View>
      </View>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  spinnerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  main: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#9BCE7B",
    paddingVertical: 40,
    paddingHorizontal: 20
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 12,
    width: "100%",
    maxWidth: 1141,
    paddingHorizontal: 45,
    paddingVertical: 40,
    zIndex: 2,
    backgroundColor: "#9BCE7B"
  },
  header: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 45,
  },
  closeButton: {},
  termsCondsTitle: {
    fontWeight: "700",
    color: "#0F172A",
    fontSize: 24,
  },
  scrollView: {
    flex: 1,
    height: "100%",
    paddingHorizontal: 50
  },
  description: { 
    textAlign: "center", 
    lineHeight: 2,
    fontSize: 14
  },
  button: {
    backgroundColor: "blue",
    marginLeft: 10,
    width: 120,
    height: 40,
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    alignSelf: "flex-end",
  }
});
// Customizable Area End
