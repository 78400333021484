import React from "react";
// Customizable Area Start
import {
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";

import { Typography } from "@builder/component-library";
import PlanCard from "../../../components/src/PlanCard";
import ArrowLeftIcon from '@mui/icons-material/ArrowBackOutlined';
// Customizable Area End

import CustomisableusersubscriptionsController, {
  Props,
  configJSON
} from "./CustomisableusersubscriptionsController";

import { SubscriptionListView } from "./SubscriptionList";

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={styles.customUserSubscriptionScreen}>
        <ScrollView>
          <View style={styles.customUserSubscriptionMain}>
            <View style={styles.customUserSubscriptionBody}>

              <View style={styles.customUserSubscriptionSubScreenHeader}>
                <TouchableOpacity testID="btnGoBack"  {...this.btnGoBackProps}  style={styles.customUserSubscriptionGobackButton}>
                  <ArrowLeftIcon sx={styles.customUserSubscriptionIcon} />
                </TouchableOpacity>
                <Typography variant="xl" style={styles.customUserSubscriptionSectionTitle}>Subscription information</Typography>
              </View>


              <View style={styles.customUserSubscriptionCurrentPlanContainer}>
                <Typography variant="xs" style={styles.customUserSubscriptionSectionTitle}>PLANS & PRICING</Typography>

                {
                  this.state.plans.map((plan, index) => {
                    return (
                      <View key={plan.name} style={styles.planCardContainer}>
                        <PlanCard
                          key={plan.name}
                          testID={index + "-btnSubscription"}
                          planName={plan.name}
                          planDescription={plan.description}
                          benefits={plan.benefits}
                          monthlyPrice={plan.monthlyPrice}
                          yearlyPrice={plan.yearlyPrice}
                          paymentTypeText={plan.paymentTypeText}
                          eventPrice={plan.eventPrice}
                          buttonActionText="Subscribe"
                          onActionButton={() => this.goToBillingInformation(plan)}
                        />
                      </View>
                    )
                  })
                }
              
              </View>


            </View>
          </View>
        </ScrollView>
      </div>
      // Customizable Area End
    );
  }
}

const styles = StyleSheet.create({
  customUserSubscriptionScreen: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#9BCE7B",
  },
  customUserSubscriptionMain: {
    position: "relative",
    flex: 1,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#9BCE7B",
    padding: 24,
    width: "100%",
    height: "100%",
  },
  customUserSubscriptionBody: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: "100%",
    maxWidth: "1100px"
  },
  customUserSubscriptionSubScreenHeader: {
    flexDirection: "row",
    alignItems: "center"
  },
  customUserSubscriptionGobackButton: {
    width: 32,
    height: 32,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    marginRight: 8
  },
  customUserSubscriptionSectionTitle: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#64748B",
    textTransform: "uppercase",
  },
  customUserSubscriptionIcon: {
    fontSize: 24,
    color: "#0F172A"
  },
  customUserSubscriptionCurrentPlanContainer: {
    marginTop: 24
  },
  planCardContainer: {
    marginBottom: 16
  }
})

// Customizable Area Start
// Customizable Area End
