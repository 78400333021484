import React from "react";

// Customizable Area Start
import { StyleSheet, View, TouchableOpacity, TouchableWithoutFeedback, ScrollView } from "react-native";
import AppHeader from "../../../components/src/AppHeader.web";
import { Typography } from "@builder/component-library";
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import ArrowDownIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpIcon from '@mui/icons-material/ArrowUpwardOutlined';
import { LineChart } from '@mui/x-charts/LineChart'
import { axisClasses } from '@mui/x-charts/ChartsAxis';
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={styles.screen}>
      <AppHeader {...this.props}/>

      <View style={styles.main}>
        <View style={styles.headerContainer}>
          <View style={styles.header}>
            <TouchableOpacity testID="btnGoBack" {...this.btnGoBackProps} style={styles.gobackButton}>
              <ArrowLeftIcon sx={styles.icon} />
            </TouchableOpacity>
            <Typography variant="xl" style={styles.headerTitle}>Analytics</Typography>
          </View>
        </View>

        <View style={styles.mainChat}>
          <View style={styles.info}>
            <Typography variant="sm" style={styles.infoLabel}>Unique app users</Typography>
            <Typography variant="3xl" style={styles.infoMainText}>13.7K</Typography>
            <View style={styles.percentageContainer}>
              <View style={styles.percentageUpIconContainer}>
                <ArrowUpIcon sx={styles.percentageUpIcon} />
              </View>
              <Typography variant="sm" style={styles.percentageUpText}>+23.32%</Typography>
            </View>
          </View>

          <LineChart
            title="teste"
            xAxis={[{ scaleType: 'point', data: ['2024-08-19', '2024-08-20', '2024-08-21', '2024-08-22', '2024-08-23', '2024-08-24', '2024-08-25'], valueFormatter: (date, context) =>
              context.location === 'tick'
                ? Intl.DateTimeFormat('us-na', { weekday: "short" }).format(new Date(date))
                : Intl.DateTimeFormat('us-na', { day: "2-digit", month: "short", year: "numeric" }).format(new Date(date)),
            }]}
            series={[
              {
                data: [1650, 2450, 1700, 2300, 1600, 1500, 2750],
                area: true,
                showMark: false,
                color: "#AA7AFF",
              },
            ]}
            grid={{ horizontal: true }}
            height={204}
            sx={chartStyling}
          />
        </View>

        <View style={styles.infoSectionCotanier}>
          <View style={styles.infoSection}>
            <View style={styles.info}>
              <Typography variant="sm" style={styles.infoLabel}>Current NPS</Typography>
              <Typography variant="3xl" style={styles.infoMainText}>8.93</Typography>
              <View style={styles.percentageContainer}>
                <View style={styles.percentageDownIconContainer}>
                  <ArrowDownIcon sx={styles.percentageDownIcon} />
                </View>
                <Typography variant="sm" style={styles.percentageDownText}>-6.12%</Typography>
              </View>
            </View>
            <View style={styles.info}>
              <Typography variant="sm" style={styles.infoLabel}>Current CLV</Typography>
              <Typography variant="3xl" style={styles.infoMainText}>$3,210</Typography>
              <View style={styles.percentageContainer}>
                <View style={styles.percentageUpIconContainer}>
                  <ArrowUpIcon sx={styles.percentageUpIcon} />
                </View>
                <Typography variant="sm" style={styles.percentageUpText}>+12.06%</Typography>
              </View>
            </View>
          </View>

          <View style={styles.infoSection}>
            <View style={styles.info}>
              <Typography variant="sm" style={styles.infoLabel}>Traffic</Typography>
              <Typography variant="3xl" style={styles.infoMainText}>10.93</Typography>
              <View style={styles.percentageContainer}>
                <View style={styles.percentageDownIconContainer}>
                  <ArrowDownIcon sx={styles.percentageDownIcon} />
                </View>
                <Typography variant="sm" style={styles.percentageDownText}>-6.12%</Typography>
              </View>
            </View>
            <View style={styles.info}>
              <Typography variant="sm" style={styles.infoLabel}>Revenue</Typography>
              <Typography variant="3xl" style={styles.infoMainText}>$10,210</Typography>
              <View style={styles.percentageContainer}>
                <View style={styles.percentageUpIconContainer}>
                  <ArrowUpIcon sx={styles.percentageUpIcon} />
                </View>
                <Typography variant="sm" style={styles.percentageUpText}>+12.06%</Typography>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const chartStyling = {
  [`.${axisClasses.tickLabel}`]: {
    fill: "#64748B !important",
    fontSize: "14px !important"
  },
  [`.MuiAreaElement-root`]: {
    fill: "#AA7AFF1A !important",
    stroke: "#6200EA !important"
  }
}

const styles = StyleSheet.create({
  screen: {
    width: "100%", 
    height: "100%", 
    backgroundColor: "#9BCE7B",
    flex: 1,
  },
  main: {
    width: "100%",
    height: "100%",
    maxWidth: 1069,
    marginTop: 50,
    marginHorizontal: "auto",
    flex: 1,
  },
  headerContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  header: {
    flexDirection: "row",
    alignItems: "center"
  },
  gobackButton: {
    width: 32,
    height: 32,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    marginRight: 8
  },
  headerTitle: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#1E293B", 
  },
  icon: {
    fontSize: 24, 
    color: "#0F172A"
  },
  mainChat: {
    width: "100%",
    height: 358,
    borderRadius: 8,
    backgroundColor: "#FFFFFF80",
    marginTop: 48,
    paddingVertical: 24,
    paddingHorizontal: 16
  },
  infoSectionCotanier: {
    flexDirection: "row",
    marginTop: 48
  },
  infoSection: {
    width: 326,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 8,
    backgroundColor: "#FFFFFF80",
    padding: 24,
    marginRight: 24
  },
  info: {},
  infoLabel: {
    color: "#64748B",
    fontWeight: "700",
  },
  infoMainText: {
    color: "#0F172A",
    fontWeight: "700",
    marginVertical: 8
  },
  percentageContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  percentageUpIconContainer: {
    width: 18,
    height: 18,
    backgroundColor: "#D1FAE5",
    borderRadius: 60,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 6,
  },
  percentageUpIcon: {
    fontSize: 14,
    color: "#059669"
  },
  percentageDownIconContainer: {
    width: 18,
    height: 18,
    backgroundColor: "#FEE2E2",
    borderRadius: 60,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 6,
  },
  percentageDownIcon: {
    fontSize: 14,
    color: "#DC2626"
  },
  percentageDownText: {
    fontWeight: "700",
    color: "#DC2626"
  },
  percentageUpText: {
    fontWeight: "700",
    color: "#059669"
  },
})
// Customizable Area End
