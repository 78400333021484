import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  SafeAreaView,
  View,
  Image,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { logo } from './assets'
import { Button, Typography } from '@builder/component-library';

// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getToken();
  }
  // Customizable Area End

  render() {
    return (
      <SafeAreaView style={styles.mainContainer}>
      {/* Customizable Area Start */}
        <ScrollView>
        <View style={styles.main}>
          <View style={styles.choseCityContainer}>
            <View style={styles.header}>
              <Image style={styles.imageLogoImage} source={{ uri: logo }} />
              <Text style={styles.title}>
                {configJSON.labelTitleText}
              </Text>
            </View>

            <View style={styles.w100}>
              {
                this.state.cities.map(city => {
                  return (
                    <TouchableOpacity
                      key={city.id}
                      testID={`btnSelectedCity-${city.id}`}
                      style={ this.state.selectedCity.id === city.id ? styles.buttonRadioSelected : styles.buttonRadio}
                      onPress={() => this.setState({ selectedCity: city })}
                    >
                      <View style={this.state.selectedCity.id === city.id ? styles.radioSelected : styles.radio} />
                      <Typography style={styles.radioText}>{city.attributes.city_name}</Typography>
                    </TouchableOpacity>
                  )
                })
              }
            </View>

            <View
              style={styles.w100}
            >
              <Button
                testID="btnContinue"
                textStyle={styles.buttonBackgroundDarkPurpleText}
                style={styles.buttonBackgroundDarkPurple}
                onPress={() => this.goToMaps()}
                text="Continue"
              />
            </View>
          </View>
        </View>
        </ScrollView>
      {/* Customizable Area End */}
      </SafeAreaView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  mainContainer: {
    width: "100%", 
    height: "100vh", 
    backgroundColor: "#9BCE7B"
  },
  main: {
    flex: 1, 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    padding: 24, 
    width: "100%", 
    height: "100%",
    backgroundColor: "#9BCE7B"
  },
  choseCityContainer: {
    flex: 1,
    display: "flex",
    padding: 10, 
    width: "100%", 
    maxWidth: 360
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageLogoImage: {
    width: 64,
    height: 64,
    resizeMode: "cover"
  },
  title: {
    fontSize: 20,
    fontWeight: "700",
    color: "#2A0066",
    marginTop: 32,
    marginBottom: 48
  },
  w100: {
    width: "100%", 
  },
  buttonRadio: {
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderColor: "#CBD5E1",
    borderRadius: 8,
    marginTop: 8,
    paddingVertical: 16,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center"
  },
  radio: {
    height: 20,
    width: 20,
    borderRadius: 12,
    borderWidth:  1,
    borderColor: '#2A0066',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonRadioSelected: {
    backgroundColor: "#E1CCFF",
    borderWidth: 1,
    borderColor: "#A666FF",
    borderRadius: 8,
    marginTop: 8,
    color: "#2A0066",
    paddingVertical: 16,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center"
  },
  radioSelected:{
    height: 20,
    width: 20,
    borderRadius: 12,
    borderWidth: 6,
    borderColor: '#2A0066',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioText: {
    fontFamily: "Inter",
    fontSize: 16, 
    fontWeight: "700", 
    color: "#2A0066",
    marginLeft: 20
  },
  buttonBackgroundDarkPurple: {
    backgroundColor: "#8861A9",
    marginTop: 48,
    marginBottom: 20
  },
  buttonBackgroundDarkPurpleText: {
    color: "#FFFFFF"
  }
});
// Customizable Area End
