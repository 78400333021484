import { Typography, Button } from "@builder/component-library";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import CloseIcon from '@mui/icons-material/Close';
import ReportProblemIcon from '@mui/icons-material/ReportProblemOutlined';
import LocationIcon from '@mui/icons-material/PlaceOutlined';

const icons = {
    ALERT: ReportProblemIcon,
    LOCATION: LocationIcon,
}

type MyProps = {
  testID: string;
  visible: boolean;
  icon?: any;
  iconColor?: string;
  title: string;
  subTitle?: string;
  primaryButtonText: string;
  primaryButtonColor: string;
  secondaryButtonText: string;
  onClose: () => void;
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
};
type MyState = {  };

export default class Popover extends Component<MyProps, MyState> {
  static propTypes = {
    testID: PropTypes.string,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      
    };
  }

  render() {
    const { testID, icon } = this.props;

    if (!this.props.visible) {
        return null
    }

    return (
        <View testID={testID} style={styles.screen}>
            <View style={styles.container}>
                <TouchableOpacity onPress={this.props.onClose} style={styles.closeButton}>
                    <CloseIcon sx={{ fontSize: 24, color: "#0F172A" }} />
                </TouchableOpacity>
                
                {
                    icon && (
                        <View style={styles.iconContainer}>  
                            {icon}
                        </View>
                    )
                }

                <Typography variant="2xl" style={styles.title}>{this.props.title}</Typography>
                {this.props.subTitle && <Typography variant="base" style={styles.subTitle}>{this.props.subTitle}</Typography>}

                <Button
                    style={styles.secondaryBackground}
                    textStyle={{ color: "#8861A9" }}
                    text={this.props.secondaryButtonText}
                    onPress={this.props.secondaryButtonAction}
                />

                <Button
                    style={{ width: 327, backgroundColor: this.props.primaryButtonColor }}
                    textStyle={{ color: "#FFF" }}
                    text={this.props.primaryButtonText}
                    onPress={this.props.primaryButtonAction}
                />  
            </View>
        </View>
    )
  }
}

const styles = StyleSheet.create({
    screen: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
    },
    container: {
        width: "100%",
        maxWidth: 669,
        borderRadius: 16,
        paddingTop: 60,
        paddingBottom: 30,
        paddingHorizontal: 100,
        backgroundColor: "#9BCE7B",
        zIndex: 2,
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
    },
    closeButton: {
        position: "absolute",
        top: 16,
        right: 18
    },
    iconContainer: {
        width: 56,
        height: 56,
        borderRadius: 999,
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 16,
        backgroundColor: "#FEE2E2"
    }, 
    textFont: {
        fontFamily: "Inter",
        textAlign: "center",
    },
    title: {
        fontFamily: "Inter",
        fontWeight: "700", 
        color: "#0F172A",
        textAlign: "center",
        marginBottom: 8
    },
    subTitle: {
        fontFamily: "Inter",
        fontWeight: "400", 
        color: "#0F172A",
        textAlign: "center",
    },
    secondaryBackground: {
        width: 327,
        marginTop: 24,
        backgroundColor: 'transparent'
    }
})