Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "StoreLocator";
exports.labelBodyText = "StoreLocator Body";
exports.getVendingMachinesEndpoint = "/bx_block_storelocator/store_locators"
exports.searchVendingMachinesEndpoint = "/bx_block_storelocator/store_locators/search?search="
exports.btnExampleTitle = "CLICK ME";
exports.goHere = "Go here";
exports.GoogleMapText = 'Google Maps';
exports.mapsText =  'Maps' ;
exports.openWithText = 'Open with';
exports.RememberMyChoice = 'Remember my choice';
exports.CancelText = 'Cancel';
exports.backgroundColorRed = 'red';
exports.WorkingText = 'Working';
exports.notWorkingText = 'Not working';

exports.backgroundColorGreen = '#09A946';
exports.borderBlueColor = '#17B6E0';
exports.blackColor = '#000000';
exports.textGreyColor = '#6B7280';
exports.buttonBlueColor = '#0073A4';
// Customizable Area End