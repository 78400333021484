import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
} from "react-native";
import { Typography, Button, Checkbox } from "@builder/component-library";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowDownIcon from '@mui/icons-material/ExpandMoreOutlined';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import NotMatchingIcon from '@mui/icons-material/ErrorOutlineOutlined';
// Customizable Area End

import FilteritemsController, {
  Props,
  configJSON
} from "./FilteritemsController";

export default class Filteritems extends FilteritemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <>
        {/* Customizable Area Start */}
        <View style={styles.sideSectionContainerHeader}>
          <Typography style={styles.sideSectionContainerHeaderTitle} variant="xl">Filters</Typography>

          <TouchableOpacity testID="btnClearFilters" style={styles.clearFiltersButton} onPress={this.handleClearSubCategories}>
            <Typography style={styles.clearFiltersButtonText} variant="sm">Clear all</Typography>
          </TouchableOpacity>

          <View style={styles.sideSectionContainerHeaderActions}>
            <TouchableOpacity testID="btnCloseSideSection" onPress={this.props.handleCloseFilters}>
              <CloseOutlinedIcon sx={styles.sideSectionContainerHeaderIcon} />
            </TouchableOpacity>
          </View>
        </View>
      

        <ScrollView style={styles.categoriesContainer}>
          {
            this.state.categories.length > 0 ? this.state.categories.map((category) => {
              return (
                <React.Fragment key={category.id}>
                  <TouchableOpacity testID={`btnCategory-${category.id}`} style={styles.category} onPress={() => this.handleToggleCategory(category.id)}>
                    <Typography variant="base" style={styles.categoryText}>{category.name}</Typography>

                    {this.state.openCategories.includes(category.id) ? <ArrowUpIcon sx={styles.chevronIcon} /> : <ArrowDownIcon sx={styles.chevronIcon} />}
                  </TouchableOpacity>

                  {this.state.openCategories.includes(category.id) && category.sub_categories.map(subCategory => {
                    return (
                      <View key={subCategory.id} style={styles.subCategory}>
                        <Typography variant="base" style={styles.subCategoryText}>{subCategory.name}</Typography>
                        
                        <Checkbox 
                          testID={`btnSubCategory-${subCategory.id}`}
                          style={styles.subCategoryCheckbox}
                          checked={this.state.selectedSubCategories.includes(subCategory.id)}
                          onPressIn={() => this.handleToggleSubCategory(subCategory.id)}
                        />
                      </View>
                    )
                  })}
                </React.Fragment>
              )
            }) : (
              <View style={styles.noResultContainer}>
                <View style={styles.noResultIconContainer}>
                  <NotMatchingIcon sx={styles.noResultIcon} />
                </View>

                <Typography style={styles.noRestulText} variant="2xl">No result found</Typography>
                
                <TouchableOpacity style={styles.noResultResetFilterButton}>
                  <Typography style={styles.noResultResetFilterButtonText} variant="sm">Reset filter</Typography>
                </TouchableOpacity>
              </View>
            )
          }
        </ScrollView>

        <Button 
          testID="btnSaveFilters"
          textStyle={styles.saveFilterButtonText}
          style={styles.saveFilterButton}
          onPress={this.handleSaveFilters}
          text="Save"
        />
        {/* Customizable Area End */}
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  sideSectionContainerHeader: {
    flexDirection: "row",
    marginTop: 36,
    paddingHorizontal: 24,
    alignItems: "center"
  },
  sideSectionContainerHeaderTitle: {
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#1E293B"
  },
  clearFiltersButton: {
    marginLeft: 22,
  },
  clearFiltersButtonText: {
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#8861A9"
  },
  sideSectionContainerHeaderIcon: {
    fontSize: 24,
    color: "#1E293B",
  },
  sideSectionContainerHeaderActions: {
    flexDirection: "row",
    marginLeft: "auto"
  },
  sideSectionContainerHeaderActionButton: {
    marginRight: 8
  },
  inputSearchSideSectionContainer: {
    height: 60,
    backgroundColor: "#FFF",
    borderRadius: 99,
    marginHorizontal: 20,
    marginTop: 26,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 22,
    paddingVertical: 18
  },
  categoriesContainer: {
    flex: 1,
    marginTop: 30,
  },
  noResultContainer: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 120
  },
  noResultIconContainer: {
    width: 56,
    height: 56,
    borderRadius: 200,
    backgroundColor: "#FEE2E2",
    alignItems: "center",
    justifyContent: "center"
  },
  noResultIcon: {
    fontSize: 26,
    color: "#0F172A"
  },
  noRestulText: {
    fontWeight: "700",
    color: "#0F172A",
    marginTop: 16
  },
  noResultResetFilterButton: {
    marginTop: 18
  },
  noResultResetFilterButtonText: {
    fontWeight: "700",
    color: "#8861A9"
  },
  category: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#E2E8F0",
    paddingVertical: 16,
    paddingHorizontal: 24
  },
  categoryText: {
    fontFamily: "Inter",
    color: "#0F172A",
    fontWeight: "700"
  },
  chevronIcon: {
    fontSize: 24,
    color: "#0F172A"
  },
  subCategory: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#E2E8F0",
    paddingVertical: 16,
    paddingHorizontal: 24,
    paddingLeft: 40
  },
  subCategoryText: {
    fontFamily: "Inter",
    fontWeight: "400"
  },
  subCategoryCheckbox: {
    padding: 0,
    margin: 0,
    color: "#8861A9"
  },
  saveFilterButton: {
    marginVertical: 30,
    marginHorizontal: 24,
    backgroundColor: "#8861A9"
  },
  saveFilterButtonText: {
    color: "#FFFFFF"
  }
});
// Customizable Area End
