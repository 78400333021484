Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpDeleteMethod = "DELETE";
exports.httpPostMethod = "POST";

exports.favouritesApiApiContentType = "application/json";
exports.favouritesApiEndPoint = "bx_block_favourites/favourites";
exports.searchFavouritesApiEndPoint = "bx_block_favourites/search_favourite?search=";
exports.deleteFavouritesApiEndPoint = "bx_block_favourites/favourites";
exports.textInputPlaceHolder = "Enter Text";
exports.contentPlaceHolder = "Enter Content";
exports.configError = "Error";
exports.configErrorType = "Please enter a type";
exports.configErrorId = "Please enter a valid id";
// Customizable Area End