// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import ActivityLog from "../../blocks/analytics/src/ActivityLog";
import Location from "../../blocks/location/src/Location";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles";
import ChangePassword from "../../blocks/customisableuserprofiles/src/ChangePassword.web"
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Imagemanagement2 from "../../blocks/imagemanagement2/src/Imagemanagement2";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Maps from "../../blocks/maps/src/Maps";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Password from "../../blocks/forgot-password/src/Password.web";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Geofence from "../../blocks/geofence/src/Geofence";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import StoreLocator from '../../blocks/storelocator/src/StoreLocator'
import FreeTrial from '../../blocks/freetrial/src/FreeTrial'

const routeMap = {
  Adminconsole2:{
    component:Adminconsole2,
    path:"/Adminconsole2"
  },
  Catalogue:{
    component:Catalogue,
    path:"/Catalogue"
  },
  LandingPage:{
    component:LandingPage,
    path:"/LandingPage"
  },
  Analytics:{
    component:Analytics,
    path:"/Analytics"
  },
  Location:{
    component:Location,
    path:"/Location"
  },
  OTPInputAuth:{
    component:OTPInputAuth,
    path:"/OTPInputAuth"
  },
  CountryCodeSelector:{
    component:CountryCodeSelector,
    path:"/CountryCodeSelector"
  },
  EmailAccountRegistration:{
    component:EmailAccountRegistration,
    path:"/EmailAccountRegistration"
  },
  EmailAccountLoginBlock:{
    component:EmailAccountLoginBlock,
    path:"/EmailAccountLoginBlock"
  },
  SocialMediaAccountLogin:{
    component:SocialMediaAccountLogin,
    path:"/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration:{
    component:SocialMediaAccountRegistration,
    path:"/SocialMediaAccountRegistration"
  },
  Imagemanagement2:{
    component:Imagemanagement2,
    path:"/Imagemanagement2"
  },
  Uploadmedia3:{
    component:Uploadmedia3,
    path:"/Uploadmedia3"
  },
  Subscriptionbilling2:{
    component:Subscriptionbilling2,
    path:"/Subscriptionbilling2"
  },
  Splashscreen:{
    component:Splashscreen,
    path:"/Splashscreen"
  },
  Filteritems:{
    component:Filteritems,
    path:"/Filteritems"
  },
  Filteroptions:{
    component:Filteroptions,
    path:"/Filteroptions"
  },
  Maps:{
    component:Maps,
    path:"/Maps"
  },
  AdvancedSearch:{
    component:AdvancedSearch,
    path:"/AdvancedSearch"
  },
  Rolesandpermissions2:{
    component:Rolesandpermissions2,
    path:"/Rolesandpermissions2"
  },
  StripePayments:{
    component:StripePayments,
    path:"/StripePayments"
  },
  ForgotPassword:{
    component:ForgotPassword,
    path:"/ForgotPassword"
  },
  ForgotPasswordOTP:{
    component:ForgotPasswordOTP,
    path:"/ForgotPasswordOTP"
  },
  Password:{
    component:Password,
    path:"/Passwords"
  },
  Settings2:{
    component:Settings2,
    path:"/Settings2"
  },
  Pushnotifications:{
    component:Pushnotifications,
    path:"/Pushnotifications"
  },
  Customisableusersubscriptions:{
    component:Customisableusersubscriptions,
    path:"/Customisableusersubscriptions"
  },
  SubscriptionDetails:{
    component:SubscriptionDetails,
    path:"/SubscriptionDetails"
  },
  TermsConditions:{
    component:TermsConditions,
    path:"/TermsConditions"
  },
  PrivacyPolicy:{
    component:TermsConditions,
    path:"/PrivacyPolicy"
  },
  TermsConditionsDetail:{
    component:TermsConditionsDetail,
    path:"/TermsConditionsDetail"
  },
  TermsConditionsUsers:{
    component:TermsConditionsUsers,
    path:"/TermsConditionsUsers"
  },
  Geofence:{
    component:Geofence,
    path:"/Geofence"
  },
  Favourites:{
    component:Favourites,
    path:"/Favourites"
  },
  AddFavourites:{
    component:AddFavourites,
    path:"/AddFavourites"
  },
  Categoriessubcategories:{
    component:Categoriessubcategories,
    path:"/Categoriessubcategories"
  },
  Emailnotifications2:{
    component:Emailnotifications2,
    path:"/Emailnotifications2"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles',
    exact: true,
  },
  ChangePassword: {
    component: ChangePassword,
    path: '/ChangePassword',
  },
  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  StoreLocator: {
    component: StoreLocator,
    path: '/StoreLocator',
  },
  FreeTrial: {
    component: FreeTrial,
    path: '/FreeTrial',
  },
  ActivityLog: {
    component: ActivityLog,
    path: '/ActivityLog',
  },
  
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;