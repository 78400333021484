Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.searchingApiEndPoint = "bx_block_advanced_search/search/search_recommendation?search=";
exports.errorTitle = 'Error'
// Customizable Area End
