import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  loading: boolean;
  businessId: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  alertVisibility: boolean;
  alertMessage: string;
  selectedAccountType: "User" | "Business";
  accountType: null | "User" | "Business";
  businessName: string;
  businessAddress: string;
  acceptTermsAndConditions: boolean;
  passwordValidations: {
    uppercase: boolean;
    lowercase: boolean;
    number: boolean;
    length: boolean;
  };
  screenStep: "SelectUserType" | "SignUpFrom" | "BusinessQuestions" | "ReviewOnGoing";
  reviewOnGoingTitle: string;
  reviewOnGoingText: string;
  businessQuestionsList: Array<{
    id: number;
    question: string;
  }>;
  businessAnswersList: Array<{
    question_id: number;
    answer: string;
  }>;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  businessQuestionApiCallId: string = "";
  businessAnswersApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  errorPasswordUppercase: string;
  errorPasswordLowercase: string;
  errorPasswordNumber: string;
  errorPasswordLength: string;
  errorAcceptTermsAndConditions: string;
  welcomeLabel: string;
  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      loading: false,
      businessId: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      acceptTermsAndConditions: false,
      countryCodeSelected: "",
      alertVisibility: false,
      alertMessage: "",
      phone: "",
      passwordValidations: {
        uppercase: false,
        lowercase: false,
        number: false,
        length: false,
      },
      selectedAccountType: "User",
      accountType: null,
      businessName: "",
      businessAddress: "",
      screenStep: "SelectUserType",
      reviewOnGoingTitle: "",
      reviewOnGoingText: "",
      businessQuestionsList: [],
      businessAnswersList: []
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("^(?=.*[A-Z])(?=.*[#!@$&*?<>',\\[\\]}{=\\-)(^%`~+.:;_])(?=.*[0-9])(?=.*[a-z]).{8,}$");
    this.emailReg = new RegExp("^[a-zA-Z0-9.!\\#$%&‘*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.welcomeLabel = configJSON.welcomeLabel;
    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;

    this.errorPasswordUppercase = configJSON.errorPasswordUppercase
    this.errorPasswordLowercase = configJSON.errorPasswordLowercase
    this.errorPasswordNumber = configJSON.errorPasswordNumber
    this.errorPasswordLength = configJSON.errorPasswordLength
    this.errorAcceptTermsAndConditions = configJSON.errorAcceptTermsAndConditions
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.isApiResponse(message)) {
      const apiRequestCallId = this.getApiRequestCallId(message);
      const responseJson = this.getResponseData(message);
      const errorResponse = this.getErrorResponse(message);
  
      if (apiRequestCallId && responseJson) {
        this.handleApiResponse(apiRequestCallId, responseJson, errorResponse);
      }
    } else if (this.isNavigationPayload(message)) {
      this.handleNavigationPayload(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  private isApiResponse(message: Message) {
    return getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }
  
  private getApiRequestCallId(message: Message) {
    return message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  }
  
  private getResponseData(message: Message) {
    return message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  }
  
  private getErrorResponse(message: Message) {
    return message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  }
  
  private handleApiResponse(apiRequestCallId: string, responseJson: any, errorResponse: any) {
    this.setState({
      loading: false
    });

    if (apiRequestCallId === this.validationApiCallId) {
      this.handleValidationApiResponse(responseJson);
    } else if (apiRequestCallId === this.createAccountApiCallId) {
      this.handleCreateAccountApiResponse(responseJson, errorResponse);
    } else if (apiRequestCallId === this.businessQuestionApiCallId) {
      this.handleBusinessQuestionsApiResponse(responseJson);
    } else if (apiRequestCallId === this.businessAnswersApiCallId) {
      this.handleBusinessAnswersApiResponse(responseJson);
    }
  }
  
  private handleValidationApiResponse(responseJson: any) {
    this.arrayholder = responseJson.data;
  
    if (this.arrayholder?.length) {
      const regexData = this.arrayholder[0];
  
      this.passwordReg = new RegExp(regexData.password_validation_regexp || '');
      this.emailReg = new RegExp(regexData.email_validation_regexp || '');
  
      this.setState({
        passwordHelperText: regexData.password_validation_rules || ''
      });
    }
  }
  
  private handleCreateAccountApiResponse(responseJson: any, errorResponse: any) {
    if (!responseJson.errors) {
      this.handleSuccessfulAccountCreation(responseJson);
    } else {
      this.parseApiErrorResponse(responseJson);
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }
  
  handleSuccessfulAccountCreation(responseJson: any) {
    if (this.state.selectedAccountType === "Business") {
      this.setState({
        otpAuthToken: responseJson.account.meta.token,
        businessId: responseJson.account.data.id
      });
      this.getBusinessQuestions();
      this.setState({ screenStep: "BusinessQuestions" });
    } else {
      this.setState({
        screenStep: "ReviewOnGoing",
        reviewOnGoingTitle: "Check your email",
        reviewOnGoingText: "We have sent a notification to your registered email id. Please validate."
      });
    }
  }
  
  private handleBusinessQuestionsApiResponse(responseJson: any) {
    if (!responseJson.errors) {
      const businessQuestions = responseJson as Array<{ question: string, id: number }>;
      const sortedBusinessQuestions = [...businessQuestions].sort((a, b) => a.id - b.id);

      this.setState({
        businessQuestionsList: sortedBusinessQuestions
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  
  private handleBusinessAnswersApiResponse(responseJson: any) {
    if (!responseJson.errors) {
      this.setState({
        screenStep: "ReviewOnGoing",
        reviewOnGoingTitle: "Account review ongoing",
        reviewOnGoingText: "Your profile is being reviewed. We will get back to you shortly."
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  
  private isNavigationPayload(message: Message) {
    return getName(MessageEnum.NavigationPayLoadMessage) === message.id;
  }
  
  private handleNavigationPayload(message: Message) {
    const otpAuthTkn = message.getData(getName(MessageEnum.AuthTokenDataMessage));
  
    if (otpAuthTkn) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  
    const navigationData = message.getData(getName(MessageEnum.NavigationUserRoleMessage));
    if (navigationData) {
      this.handleUserRoleNavigation(navigationData);
    }
  }
  
  private handleUserRoleNavigation({ userRole, userData, acceptTermsAndConditions }: any) {
    if (userRole) {
      this.setState({
        accountType: userRole,
        selectedAccountType: userRole,
        screenStep: "SignUpFrom",
        acceptTermsAndConditions: acceptTermsAndConditions
      });
    }
  
    if (userData) {
      this.setUserData(userRole, userData);
    }
  }
  
  private setUserData(userRole: string, userData: any) {
    if (userRole === "User") {
      this.setState({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        password: userData.password,
      });
    } else {
      this.setState({
        businessAddress: userData.businessAddress,
        businessName: userData.businessName,
        email: userData.email,
        password: userData.password,
      });
    }

    this.setState({
      passwordValidations: {
        uppercase: /[A-Z]/.test(userData.password),
        lowercase: /[a-z]/.test(userData.password),
        number: /\d/.test(userData.password),
        length: userData.password.length >= 8,
      },
    })
  }

  navigateUserToPrivacyOrTerms(type: "PrivacyPolicy" | "TermsAndCondition", msg: Message) {
    const termsAndConditionType = this.state.selectedAccountType === "User" ? "users" : "business"

    msg.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      type,
      termsAndConditionType,
      acceptTermsAndConditions: this.state.acceptTermsAndConditions,
      data: termsAndConditionType === "users" ? {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
      } : {
        businessName: this.state.businessName,
        email: this.state.email,
        password: this.state.password,
        businessAddress: this.state.businessAddress
      } 
    });

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }
  
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    
    this.navigateUserToPrivacyOrTerms("PrivacyPolicy", msg)
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    this.navigateUserToPrivacyOrTerms("TermsAndCondition", msg)
  }

  showAlert(alertType: string, alertMessage: string) {
    window.scrollTo({
      top: 0
    })

    this.setState({
      alertVisibility: true,
      alertMessage: alertMessage
    })
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  setAcceptTermsAndConditions = (value: boolean) => {
    this.setState({ acceptTermsAndConditions: value });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.state.accountType === 'User' && (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password))
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    if (!this.validateSignUpFormBusinees()) {
      return false;
    }

    if (!this.validateSignUpEmailAndPassword()) return false;

    this.setState({
      alertVisibility: false,
      alertMessage: "",
      loading: true,
    })

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = this.state.accountType === "User" ? {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
    } : {
      user_name: this.state.businessName,
      email: this.state.email,
      password: this.state.password,
      address: this.state.businessAddress,
    };

    const data = {
      type: this.state.accountType,
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  sendBusinessAnswers(): boolean {
    if (this.state.businessQuestionsList.length > this.state.businessAnswersList.length) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    this.setState({
      alertVisibility: false,
      alertMessage: "",
      loading: true,
    })

    const header = {
      "Content-Type": configJSON.businessQuestionsApiContentType,
      token: this.state.otpAuthToken
    };

    const httpBody = {
      account: {
        answers: this.state.businessAnswersList,
      },

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.businessAnswersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendBuisnessQuestionsAPiEndPoint.replace(':id', this.state.businessId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sendBusinessQuestionsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getBusinessQuestions() {
    const headers = {
      "Content-Type": configJSON.businessQuestionsApiContentType
    };

    const getBusinessQuestionsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.businessQuestionApiCallId = getBusinessQuestionsMsg.messageId;

    getBusinessQuestionsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBuisnessQuestionsAPiEndPoint
    );

    getBusinessQuestionsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getBusinessQuestionsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBusinessQuestionsApiMethodType
    );
    runEngine.sendMessage(getBusinessQuestionsMsg.id, getBusinessQuestionsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  validateSignUpFormBusinees() {
    if (
      this.state.accountType === 'Business' && (
      this.isStringNullOrBlank(this.state.businessName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.businessAddress) ||
      this.isStringNullOrBlank(this.state.password))
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    return true
  }

  validateSignUpEmailAndPassword() {
    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!(/[A-Z]/.test(this.state.password))) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordUppercase);
      return false;
    }

    if (!(/[a-z]/.test(this.state.password))) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordLowercase);
      return false;
    }

    if (!(/\d/.test(this.state.password))) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordNumber);
      return false;
    }

    if (!(this.state.password.length >= 8)) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordLength);
      return false;
    }

    if (!this.state.acceptTermsAndConditions) {
      this.showAlert(configJSON.errorTitle, this.errorAcceptTermsAndConditions);
      return false;
    }

    return true
  }

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnAcceptPrivacyAndTermsProps = {
    onPressIn: () => this.setAcceptTermsAndConditions(!this.state.acceptTermsAndConditions)
  }

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  btnSelectedIndividual = {
    onPress: () => this.setState({ selectedAccountType: 'User' })
  };

  btnSelectedBusiness = {
    onPress: () => this.setState({ selectedAccountType: 'Business' })
  };

  btnSelectedAccountType = {
    onPress: () => this.setState({ 
        accountType: this.state.selectedAccountType,
        screenStep: "SignUpFrom",
      })
  };

  btnSignUpBusiness = {
    onPress: () => this.sendBusinessAnswers()
  };

  txtBusinessNameProps = {
    onChangeText: (text: string) => {
      this.setState({ businessName: text });

      //@ts-ignore
      this.txtBusinessNameProps.value = text;
    }
  };

  txtBusinessAddressProps = {
    onChangeText: (text: string) => {
      this.setState({ businessAddress: text });

      //@ts-ignore
      this.txtBusinessAddressProps.value = text;
    }
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  handleUpdateBusinessAnwser = (question_id: number, text: string) => {
    this.setState({
      businessAnswersList: this.state.businessAnswersList.find(answer => answer.question_id === question_id) ? [
        ...this.state.businessAnswersList.filter(answer => answer.question_id !== question_id),
        {
          question_id,
          answer: text
        }
      ] : [
        ...this.state.businessAnswersList,
        {
          question_id,
          answer: text
        }
      ]
    })
  }

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({
        password: text,
        passwordValidations: {
          uppercase: /[A-Z]/.test(text),
          lowercase: /[a-z]/.test(text),
          number: /\d/.test(text),
          length: text.length >= 8,
        },
      });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
