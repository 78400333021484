import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  // Customizable Area Start
  // Customizable Area End
  IconButton,
} from "@mui/material";

// Customizable Area Start
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native'
import { Typography as Text, Button as ButtonBuilder } from "@builder/component-library";
import HeartIcon from '@mui/icons-material/FavoriteBorderOutlined';
import HeartFilledIcon from '@mui/icons-material/FavoriteOutlined';
import StarIcon from '@mui/icons-material/StarOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import WebIcon from '@mui/icons-material/PublicOutlined';
import PhoneIcon from '@mui/icons-material/LocalPhone';
import {
  Theme,
} from "@mui/material/styles";
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import { facebook, instagram } from './assets'
// Customizable Area End

import StoreLocatorController, {
  Props,
  configJSON,
} from "./StoreLocatorController";
import "@mui/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class StoreLocator extends StoreLocatorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={styles.screen}>
        <View style={styles.container}>
          <View style={styles.screenHeader}>
            <TouchableOpacity testID="btnGoBack" style={styles.gobackButton}  {...this.btnGoBackProps}>
              <ArrowLeftIcon sx={styles.icon} />
            </TouchableOpacity>
          </View>

          <img src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} style={styles.businessImage}  />

          <View style={styles.businessInfoContainer}>
            <View style={styles.businessInforHeader}>
              <Text variant="2xl" style={styles.businessName}>Business Name</Text>
              <TouchableOpacity>
                <HeartIcon sx={styles.favouriteIcon} />
              </TouchableOpacity>
            </View>
            <View style={styles.businessRatingContainer}>
              <Text variant="base" style={styles.businessRatingText}>
                4.0
              </Text>
              <View style={styles.businessRating}>
                  {Array.from({ length: 5 }).map((_, index) => {
                      return (
                        <StarIcon key={index} sx={{ fontSize: 16, color: index >= 4 ? '#E2E8F0' : '#FCD34D' }} />
                      )
                  })}
              </View>
              <Text variant="base" style={styles.defaultText}>
                12 reviews
              </Text>
            </View>

            <Text variant="base" style={styles.defaultText}>
              Bustling retail & consignment store for rare & popular sneakers in an airy, polished setting.
            </Text>
          </View>

          <View style={styles.separator} />

          <View style={styles.sectionContainer}>
            <LocationIcon sx={styles.sectionIcon} />
            <View style={styles.section}>
              <Text variant="base" style={styles.defaultText}>
                Cornelis Schuytstraat 58-60 Amsterdam, NL 1071JL
              </Text>

              <ButtonBuilder
                testID="btnShowOnTheMap"
                style={styles.button}
                textStyle={{ color: "#8861A9" }}
                text="Show on the map"
                {...this.btnOpenMap}
              />  
            </View>
          </View>

          <View style={styles.sectionContainer}>
            <TimeIcon sx={styles.sectionIcon} />

            <Text variant="base" style={styles.openText}>Open</Text>
            <Text variant="base" style={styles.defaultText}>• Closes at 7pm</Text>    

          </View>

          <View style={styles.sectionContainer}>
            <WebIcon sx={styles.sectionIcon} />
            <View style={styles.section}>
              <Text variant="base" style={styles.defaultText}>businessname.com</Text>    
            </View>
          </View>

          <View style={styles.sectionContainer}>
            <PhoneIcon sx={styles.sectionIcon} />
            <View style={styles.section}>
              <Text variant="base" style={styles.defaultText}>03-7932 2259</Text>    
            </View>
          </View>

          <View style={styles.sectionContainer}>
            <img src={facebook} style={styles.sectionSocialMediaImage} />
            <View style={styles.section}>
              <Text variant="base" style={styles.defaultText}>facebook.com/businessname</Text>    
            </View>
          </View>

          <View style={styles.sectionContainer}>
            <img src={instagram} style={styles.sectionSocialMediaImage} />
            <View style={styles.section}>
              <Text variant="base" style={styles.defaultText}>instagram.com/businessname</Text>    
            </View>
          </View>

          <View style={styles.separator} />

          <View style={styles.galleryContainer}>
            <Text variant="xl" style={styles.boldText}>Photos</Text>    

            <ScrollView horizontal={true} style={styles.gallery}>
              <img style={styles.galleryPhoto} src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />    
              <img style={styles.galleryPhoto} src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />    
              <img style={styles.galleryPhoto} src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />    
              <img style={styles.galleryPhoto} src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />    
              <img style={styles.galleryPhoto} src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />    
              <img style={styles.galleryPhoto} src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />    
              <img style={styles.galleryPhoto} src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />    
              <img style={styles.galleryPhoto} src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />    
              <img style={styles.galleryPhoto} src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />    
              <img style={styles.galleryPhoto} src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />    
              <img style={styles.galleryPhoto} src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />    
              <img style={styles.galleryPhoto} src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />    

            </ScrollView>

          </View>

        </View>
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  screen: { 
    width: "100vw", 
    minHeight: "100%", 
    alignItems: "center",
    backgroundColor: "#9BCE7B", 
    padding: 24,
  },
  container: { 
    display: "flex", 
    flexDirection: "column", 
    padding: 10, 
    width: "100%", 
    height: "100%",
    maxWidth: 700 
  },
  screenHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 24
  },
  gobackButton: {
    width: 32,
    height: 32,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    marginRight: 8
  },
  icon: {
    fontSize: 24, 
    color: "#0F172A"
  },
  businessImage: {
    width: "100%",
    maxHeight: 600,
    resizeMode: "cover",
    borderRadius: 16,
  },
  businessInfoContainer: {
    marginBottom: 16,
    marginTop: 30
  },
  businessInforHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  businessName: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#0F172A",
  },
  businessRatingContainer: {
    flexDirection: "row"
  },
  businessRatingText: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#0F172A",
  },
  businessRating: {
    flexDirection: "row",
    marginHorizontal: 8
  },
  defaultText: {
    fontFamily: "Inter",
    fontWeight: "400", 
    color: "#0F172A",
  },
  boldText: {
    fontFamily: "Inter",
    fontWeight: "400", 
    color: "#0F172A",
  },
  favouriteIcon: {
    fontSize: 20, 
    color: "#8861A9"
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#E2E8F0",
    marginBottom: 16
  },
  sectionContainer: {
    flexDirection: "row",
    marginBottom: 16
  },
  section: { 
    flex: 1, 
    marginLeft: 16 
  },
  sectionIcon: {
    fontSize: 24, 
    color: "#8861A9"
  },
  sectionSocialMediaImage: {
    width: 24,
    height: 24
  },
  button: {
    backgroundColor: "#E1CCFF",
    marginTop: 8
  },
  openText: {
    fontFamily: "Inter",
    fontWeight: "400", 
    color: "#059669",
    marginLeft: 16,
    marginRight: 4,
  },
  closeText: {
    fontFamily: "Inter",
    fontWeight: "400", 
    color: "#DC2626",
    marginLeft: 16,
    marginRight: 4,
  },
  galleryContainer: {
    marginTop: 16
  },
  gallery: {
    marginTop: 16
  },
  galleryPhoto: {
    width: 104,
    marginRight: 8,
    borderRadius: 8,
    resizeMode: "cover",
    paddingBottom: 10
  }
});
// Customizable Area End
