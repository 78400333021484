import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  inputSearchLogsText: string;
  activeLog: Array<{ id: string, description: string, time: string }>;
  filteredActiveLog: Array<{ id: string, description: string, time: string }>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ActivityLogController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  inputSearchRef = React.createRef<HTMLInputElement>()
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      inputSearchLogsText: "",
      activeLog: [
        { id: "1", description: "Liked the post #0567900341", time: "06:21 AM" }, 
        { id: "2", description: "Logged in", time: "06:11 AM" }, 
        { id: "3", description: "Logged out", time: "06:08 AM" }, 
        { id: "4", description: "Added to friend Mary Lee", time: "06:07 AM" }, 
        { id: "5", description: "Added comment “Good choice” for the post #0912300301", time: "06:01 AM" }, 
        { id: "6", description: "Added reaction for the post  #0567900341", time: "05:58 AM" }, 
        { id: "7", description: "Changed a password", time: "1 day ago" }, 
        { id: "8", description: "Added reaction  for the post #0567900341", time: "1 day ago" }, 
        { id: "9", description: "Added comment “looks good” for the post #0567900341", time: "1 day ago" },
        { id: "10", description: "Changed a password", time: "2 day ago" }, 
      ],
      filteredActiveLog: [
        { id: "1", description: "Liked the post #0567900341", time: "06:21 AM" }, 
        { id: "2", description: "Logged in", time: "06:11 AM" }, 
        { id: "3", description: "Logged out", time: "06:08 AM" }, 
        { id: "4", description: "Added to friend Mary Lee", time: "06:07 AM" }, 
        { id: "5", description: "Added comment “Good choice” for the post #0912300301", time: "06:01 AM" }, 
        { id: "6", description: "Added reaction for the post  #0567900341", time: "05:58 AM" }, 
        { id: "7", description: "Changed a password", time: "1 day ago" }, 
        { id: "8", description: "Added reaction  for the post #0567900341", time: "1 day ago" }, 
        { id: "9", description: "Added comment “looks good” for the post #0567900341", time: "1 day ago" }, 
        { id: "10", description: "Changed a password", time: "2 day ago" }, 
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  inputSearchLogsProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => { 
      this.setState({ 
        inputSearchLogsText: event.target.value,
        filteredActiveLog: this.state.activeLog.filter(activeLog => activeLog.description.toLowerCase().includes(event.target.value.toLowerCase())) 
      })
    }
  }

  btnGoBackProps = {
    onPress: () => this.props.navigation.goBack()
  };

  handleFocusInputSearchLogs = () => {
    this.inputSearchRef.current?.focus();
  }
  // Customizable Area End
}
