Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getCitiesApiContentType = "application/json";
exports.getCitiesAPiEndPoint = "bx_block_address/listed_city";
exports.getCitiesAPiMethod = "GET";
exports.getCitiesApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Choose city";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End