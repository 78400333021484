import React from "react";

// Customizable Area Start
import { Input, Button, Typography, Checkbox } from '@builder/component-library';

import { View, StyleSheet, Platform } from "react-native";
import { imgVisbility, imgVisbilityOff } from "./assets";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <View style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#9BCE7B", padding: 24 }}>
        <View style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "10px", width: "100%", maxWidth: 360 }}>
          {
            this.state.alertVisibility && (
              <View style={{ paddingVertical: 21, paddingHorizontal: 16, width: "100%", display: "flex", alignItems: "flex-start", backgroundColor: "#FEE2E2", borderRadius: 4, borderLeftWidth: 4, borderLeftColor: "#DC2626", marginBottom: 48 }}>
                <Typography style={{ fontFamily: "Inter", color: "#DC2626", fontSize: 12 }}>{this.state.alertMessage}</Typography>
              </View>
            )
          }

          {
            this.state.screenStep === "form" ? (
              <View style={styles.formWrapper}>
                <View style={{flex: 1, marginBottom: "24px" }}>
                  <Typography variant="sm" style={{ fontFamily: "Inter", fontWeight: "700", color: "#334155" }}>Email</Typography>
                  <Input
                    containerStyle={{
                      width: "100%",
                      backgroundColor: "#FFFFFF",
                      ...Platform.select({
                        web: {
                          borderWidth: 0, 
                          outlineStyle: "none"
                        },
                        default: {}
                      })
                    }}
                    testID="txtInputEmail"
                    placeholder={this.state.placeHolderEmail}
                    placeholderTextColor={"#94A3B8"}
                    value={this.state.email}
                    {...this.txtInputEmailProps} 
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <Typography variant="sm" style={{ fontFamily: "Inter", fontWeight: "700", color: "#334155" }}>Password</Typography>
                  <Input
                    containerStyle={{
                      width: "100%",
                      backgroundColor: "#FFFFFF",
                      ...Platform.select({
                        web: {
                          borderWidth: 0, 
                          outlineStyle: "none"
                        },
                        default: {}
                      })
                    }}
                    testID="txtInputPassword"
                    placeholder={this.state.placeHolderPassword}
                    placeholderTextColor={"#94A3B8"}
                    value={this.state.password}
                    {...this.txtInputPasswordProps} 
                    secureTextEntry={this.state.enablePasswordField}
                    rightIcon={
                      <Button
                        testID="btnTogglePassword"
                        aria-label="toggle password visibility"
                        {...this.btnPasswordShowHideProps} 
                        style={[styles.removeBackground]}
                        icon={!this.state.enablePasswordField ? (
                          <img src={imgVisbility} />
                        ) : (
                          <img src={imgVisbilityOff} />
                        )}
                      />
                    }
                  />
                </View>
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                  <Checkbox
                    testID={"btnRememberMe"}
                    style={{ marginVertical: 0, marginHorizontal: 0, padding: 0 }}
                    textStyle={{ fontSize: 14, color: "#0F172A",fontWeight: "400" }}
                    title="Remember me"
                    onPressIn={() => this.setRememberMe(!this.state.checkedRememberMe)}
                    checked={this.state.checkedRememberMe}
                  />
                  <Button
                    testID="btnForgotPassword"
                    style={styles.removeBackground}
                    textStyle={{ fontWeight: "700", fontSize: 14, color: "#8861A9" }}
                    onPress={() => this.goToForgotPassword()}
                    text="Forgot Password?"
                  />
                </View>
                <View
                  style={{ flex: 1 }}
                >
                  <Button
                    testID="btnEmailLogIn"
                    textStyle={{ color: "#FFF" }}
                    style={{ backgroundColor: "#8861A9", marginTop: "48px" }}
                    loading={this.state.loading}
                    {...this.btnEmailLogInProps}
                    text={this.state.loading ? "" : "Log in"}
                  />
                </View>
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 24 }}>
                  <Typography variant="base" style={{ fontFamily: "Inter", color: "#0F172A"}} component="span">
                    Don't have an account?
                  </Typography>
                  <Button
                    testID={"btnSignUp"}
                    style={styles.removeBackground}
                    textStyle={{ fontSize: 16, fontWeight: "700", color: "#8861A9" }}
                    onPress={() => this.goToSignUp()}
                    text="Sign Up"
                  />
                </View>
              </View>
            ) : (
              <View style={{ flex: 1,  alignItems: "center", width: "100%", maxWidth: 360 }}>
                <Typography testID="reviewOnGoingTitle" variant="xl" style={{ fontFamily: "Inter", fontWeight: "700", color: "#0F172A", marginBottom: 8, textAlign: "center" }}>Account review ongoing</Typography>
                <Typography testID="reviewOnGoingText" variant="lg" style={{ fontFamily: "Inter", fontWeight: "400", color: "#0F172A",textAlign: "center" }}>Your profile is being reviewed. We will get back to you shortly.</Typography>

                <View style={{ width: "100%" }}>
                  <Button
                    testID="btnReviewOnGoing"
                    textStyle={{ color: "#FFF" }}
                    style={{ backgroundColor: "#8861A9", marginTop: 48 }}
                    onPress={() => this.setState({ screenStep: "form" })}
                    text="Ok"
                  />
                </View>

              </View>
            )
          }
        </View>
      </View>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
