import React from "react";

// Customizable Area Start
import {
    View,
    Button,
    StyleSheet,
    ScrollView,
    Text,
    TextInput,
    Image,
    KeyboardAvoidingView,
    TouchableOpacity,
} from "react-native";

import { Formik } from "formik";
import { correct, wrong } from "./assets";
import * as Yup from "yup";

const fontFamily = '"Inter", sans-serif';
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.isChangePassword = true;
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <KeyboardAvoidingView
                behavior= "padding"
                style={{ flex: 1 }}
            >
                <ScrollView
                    keyboardShouldPersistTaps="always"
                    style={
                         styles.containerWeb
                    }
                >

                    <View>
                        <View style={styles.headline}>
                            <Text style={styles.titleText}>
                            New password
                            </Text>
                            <Text style={styles.stepText}>
                                Enter a new password for
                            </Text>
                            <div style={styles.text}>example@gmail.com</div>
                        </View>
                    </View>
                    <View>
                        <Formik
                            initialValues={{ password: "", confirmPassword: "" }}
                            validationSchema={Yup.object().shape(this.state.passwordSchema)}
                            validateOnMount={true}
                            validateOnChange={true}
                            onSubmit={(values, actions) => {
                                this.goToConfirmationAfterPasswordChange(values);
                                actions.setSubmitting(false);
                            }}
                            data-testid="changePasswordForm"
                        >
                            {({
                                handleChange,
                                handleSubmit,
                                errors,
                                setFieldTouched,
                                touched,
                            }) => (
                                <View>
                                    <Text style={styles.inputLabel}>New Password</Text>

                                    <View
                                        style={
                                             styles.passwordContainerWeb
                                        }
                                    >
                                        <TextInput
                                            testID={"txtInputPassword"}
                                            style={
                                                
                                                    styles.passwordInputWeb
                                            }
                                            placeholder="Your password"
                                            placeholderTextColor={"#767676"}
                                            onChange={(event: any) => this.setPassword(event.target.value)}
                                            value={this.state.password}
                                        />

                                        <TouchableOpacity
                                            style={styles.passwordShowHide}
                                            onPress={() => {
                                                this.setState({
                                                    enablePasswordField:
                                                        !this.state.enablePasswordField,
                                                });
                                            }}
                                            testID="passwordShowHideIcon"
                                        >
                                            <Image
                                                style={styles.imgPasswordShowhide}
                                                source={
                                                    this.state.enablePasswordField
                                                        ? this.imgPasswordVisible
                                                        : this.imgPasswordInVisible
                                                }
                                            />
                                        </TouchableOpacity>
                                    </View>
                                    <View>
                                        <Text
                                            style={
                                                styles.passwordRulesStyle
                                            }
                                        >
                                            {this.state.passwordRules}
                                        </Text>
                                    </View>
                                    <Text style={styles.inputLabel}>Confirm Password</Text>

                                    <View
                                        style={
                                          
                                                styles.passwordContainerWeb
                                        }
                                    >
                                        <TextInput
                                            testID={"txtInputConfirmPassword"}
                                            style={
                                                    styles.passwordInputWeb
                                            }
                                            placeholder="Confirm your new password"
                                            placeholderTextColor="#767676"
                                            onChange={(event: any) => this.setConfirmPassword(event.target.value)}
                                            value={this.state.confirmPassword}
                                        />

                                        <TouchableOpacity
                                            style={styles.passwordShowHide}
                                            onPress={() => {
                                                this.setState({
                                                    btnConfirmPasswordShowHide:
                                                        !this.state.btnConfirmPasswordShowHide,
                                                });
                                            }}
                                            testID="confirmPasswordShowHide"
                                        >
                                            <Image
                                                style={styles.imgPasswordShowhide}
                                                source={
                                                    this.state.btnConfirmPasswordShowHide
                                                        ? this.imgPasswordVisible
                                                        : this.imgPasswordInVisible
                                                }
                                            />
                                        </TouchableOpacity>
                                    </View>
                                    <div style={{ marginTop: "20px", fontSize: "16px" }}>Your password must contain</div>
                                    <div style={{ width: "100%", fontSize: "13px", marginLeft: "5px", marginBottom: "15px" }}>
                                        <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>{this.state.passwordValidations.uppercase ? <img style={{ width: "15px" }} src={correct} alt="correct" /> : <img style={{ width: "15px" }} src={wrong} alt="wrong" />}At least one capital letter</p>
                                        <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>{this.state.passwordValidations.lowercase ? <img style={{ width: "15px" }} src={correct} alt="correct" /> : <img style={{ width: "15px" }} src={wrong} alt="wrong" />} At least one lowercase letter</p>
                                        <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>{this.state.passwordValidations.number ? <img style={{ width: "15px" }} src={correct} alt="correct" /> : <img style={{ width: "15px" }} src={wrong} alt="wrong" />} At least one number</p>
                                        <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>{this.state.passwordValidations.length ? <img style={{ width: "15px" }} src={correct} alt="correct" /> : <img style={{ width: "15px" }} src={wrong} alt="wrong" />}Minimum character length is 8 characters</p>
                                    </div>

                                    <View style={{ zIndex: -1, padding: 5, borderRadius: 5, backgroundColor: "black" }}>
                                        <Button
                                            testID={"changePasswordButton"}   
                                            title="Set new password"
                                            color="black"
                                            onPress={() => handleSubmit()}
                                        />
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: "10px", marginTop: "15px" }}>
                                        <Text style={{ fontFamily: fontFamily }}>
                                            Back to  
                                        </Text>
                                        <TouchableOpacity onPress={() => this.goToLogin()} testID={"btnSignUp"}>
                                            <Text style={styles.linkText}>Log in</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            )}
                        </Formik>
                    </View>
                    {/* Customizable Area End */}
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }
}

// Customizable Area Start
const styles = StyleSheet.create({
    containerMobile: {
        flex: 1,
        padding: 16,
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        maxWidth: 650,
        backgroundColor: "#fff",
    },
    containerWeb: {
        padding: 16,
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 650,
        fontFamily: fontFamily
    },
    countryCodeSelector: {
        flex: 3,
        marginTop: 20,
        textAlign: "left",
        textAlignVertical: "center",
    },
    button: {
        marginTop: 16,
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
        zIndex: -1,
    },

    flexContainer: {
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "flex-end",
        justifyContent: "flex-start",
        width: "100%",
    },
    linkText: {
        color: "black",
        fontWeight: "bold",
        marginLeft: 5,
    },
    removeBackground: {
        backgroundColor: "transparent"
    },
    headline: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "left"
    },

    webInput: {
        marginTop: 20,
        width: "100%",
        zIndex: -1,
    },
    checkBoxText: {
        fontSize: 18,
        color: "#6200EE",
        fontWeight: "bold",
        fontFamily: fontFamily
    },

    inputAfterCountryCode: {
        width: "100%",
        zIndex: -1,
    },

    mobileInput: {
        flexDirection: "row",
        fontSize: 16,
        textAlign: "left",
        backgroundColor: "#00000000",
        marginTop: 20,
        borderWidth: 1,
        borderColor: "#767676",
        borderRadius: 2,
        includeFontPadding: true,
    },

    codeInput: {
        marginTop: 20,
        width: "30%",
    },

    phoneInput: {
        flex: 3,
        marginTop: 20,
    },

    noBorder: {
        borderBottomWidth: 0,
    },

    titleText: {
        fontSize: 32,
        color: "black",
        fontWeight: "bold",
        width: "100%"
    },

    text: {
        width: "100%",
        marginBottom: 32,
    },

    stepText: {
        fontSize: 16,
        textAlign: "left",
        marginVertical: 8,
        width: "100%"
    },

    emailText: {
        marginBottom: 16,
        fontSize: 16,
        textAlign: "left",
        marginVertical: 8,
        fontWeight: "bold",
    },

    bgRectBorder: {
        borderWidth: 1,
        borderColor: "#767676",
        borderRadius: 2,
        marginTop: 20,
        minHeight: 40,
        fontSize: 18,
        textAlignVertical: "center",
        padding: 10,
    },

    bgRectWeb: {
        marginTop: 40,
    },

    errorStyle: {
        color: "red",
        textAlign: "center",
    },

    passwordShowHide: {
        alignSelf: "center",
        marginRight: 10
    },

    passwordRulesStyle: {
        padding: 15,
    },

    bgPasswordContainer: {
        flexDirection: "row",
        backgroundColor: "#00000000",
        marginTop: 20,
        borderWidth: 1,
        borderColor: "#767676",
        borderRadius: 2,
        paddingLeft: 5,
        paddingRight: 5,
    },

    passwordContainerWeb: {
        flexDirection: "row",
        borderWidth: 1,
        borderColor: "#CBD5E1",
        borderRadius: 8,
        marginRight: 10,
    },
    bgPasswordInput: {
        flex: 1,
        fontSize: 16,
        textAlign: "left",
        backgroundColor: "#00000000",
        minHeight: 40,
        includeFontPadding: true,
    },

    inputLabel: {
        fontSize: 16,
        marginBottom: 5,
        fontWeight: "bold"
    },
    passwordInputWeb: {
        flex: 1,
        fontSize: 18,
        textAlign: "left",
        backgroundColor: "#00000000",
        minHeight: 40,
        includeFontPadding: true,
        // borderColor: "#767676",
        borderRadius: 2,
        // border: "1px solid black",
        paddingLeft: 10,
        paddingRight: 10,
        width: "100%",
    },
    imgPasswordShowhide:  { height: 30, width: 30 },
});
// Customizable Area End