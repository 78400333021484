import React from "react";
// Customizable Area Start
import { Button } from "@builder/component-library";
import { View, Text, StyleSheet, ImageBackground, Image } from "react-native";

// Merge Engine - import assets - Start
import { imgBackground, imgSplash } from "./assets";
// Merge Engine - import assets - End

// Customizable Area End

import SplashscreenController, {
  Props,
  configJSON,
} from "./SplashscreenController";

export default class Splashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start

    return (
      // @ts-ignore 
      <ImageBackground source={imgBackground} style={styles.main}>

        <View style={styles.body}>
          <View />

          <View style={styles.header}>
            <Image style={styles.imageLogoImage} source={imgSplash} />

          </View>

          <View style={styles.btnFooter}>
            <Text style={styles.textWelcomeLabel}>
              {configJSON.welcomeLabel}
            </Text>

            <Button
              data-test-id={"btnSignUp"}
              style={{ marginBottom: "18px", backgroundColor: "#8861A9" }}
              textStyle={{ color: "#FFFFFF", fontSize: 16 }}
              onPress={() => this.goToSignUp()}
              text="Sign up"
            />
            <Button
              data-test-id={"btnLogIn"}
              style={{ backgroundColor: "#F0E5FF" }}
              textStyle={{ color: "#6200EA", fontSize: 16 }}
              onPress={() => this.goToLogIn()}
              text="Log in"
            />
          </View>
        </View>
      </ImageBackground>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  main: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    resizeMode: "cover"
  },
  body: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: 360,
  },
  header: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageLogoImage: {
    width: 100,
    height: 100,
    marginBottom: 80,
    resizeMode: "cover"
  },
  textWelcomeLabel: {
    fontSize: 20,
    fontWeight: "700",
    color: "#2A0066",
    marginBottom: 44
  },
  btnFooter: {
    width: "100%",
    display: "flex",
    textAlign: "center",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16, 
    backgroundColor: "#9BCE7B",
    paddingHorizontal: 24,
    paddingVertical: 40
  },
});
// Customizable Area End
