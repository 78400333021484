import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  token: string;
  email: string;
  newPassword: string;
  confirmNewPassword: string;
  enableNewPasswordField: boolean;
  enableConfirmNewPasswordField: boolean;
  alertVisibility: boolean;
  alertMessage: string;
  passwordValidations: {
    uppercase: boolean;
    lowercase: boolean;
    number: boolean;
    length: boolean;
  };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  errorPasswordUppercase: string;
  errorPasswordLowercase: string;
  errorPasswordNumber: string;
  errorPasswordLength: string;
  setNewPasswordCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.errorPasswordUppercase = configJSON.errorPasswordUppercase
    this.errorPasswordLowercase = configJSON.errorPasswordLowercase
    this.errorPasswordNumber = configJSON.errorPasswordNumber
    this.errorPasswordLength = configJSON.errorPasswordLength

    this.state = {
      loading: false,
      token: "",
      email: "",
      newPassword: "",
      confirmNewPassword: "",
      enableNewPasswordField: true,
      enableConfirmNewPasswordField: true,
      alertVisibility: false,
      alertMessage: "",
      passwordValidations: {
        uppercase: false,
        lowercase: false,
        number: false,
        length: false,
      },
    };
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')
    const email = params.get('email') || ""


    if (!token) {
      this.showAlert("Error", "Token is missing! try to send the link again.")
      return;
    }
    
    this.setState({
      token,
      email
    })
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({
      loading: false
    })
    if (this.isSetNewPasswordApiResponse(message)) {
      this.handleSetNewPasswordResponse(message);
    }
    // Customizable Area End
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area Start
  private isSetNewPasswordApiResponse(message: Message): boolean {
    return (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.setNewPasswordCallId !== null &&
      this.setNewPasswordCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    );
  }
  
  private handleSetNewPasswordResponse(message: Message) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    if (responseJson.errors) {
      this.showAlert("Error", responseJson.errors[0].token || responseJson.errors[0].password);
    } else {
      this.goToLogin();
    }
  }
  showAlert(alertType: string, alertMessage: string) {

    this.setState({
      alertVisibility: true,
      alertMessage: alertMessage
    })
  }

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnSetNewPasswordProps = {
    onPress: () => this.setNewPassword()
  }

  btnNewPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enableNewPasswordField: !this.state.enableNewPasswordField });
      this.txtInputNewPasswordProps.secureTextEntry = !this.state
        .enableNewPasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputNewPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  txtInputNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({
        newPassword: text,
        passwordValidations: {
          uppercase: /[A-Z]/.test(text),
          lowercase: /[a-z]/.test(text),
          number: /\d/.test(text),
          length: text.length >= 8,
        },
      });

      //@ts-ignore
      this.txtInputNewPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  btnConfirmNewPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enableConfirmNewPasswordField: !this.state.enableConfirmNewPasswordField });
      this.txtInputNewPasswordProps.secureTextEntry = !this.state
        .enableConfirmNewPasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputConfirmNewPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  txtInputConfirmNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({
        confirmNewPassword: text,
        passwordValidations: {
          uppercase: /[A-Z]/.test(text),
          lowercase: /[a-z]/.test(text),
          number: /\d/.test(text),
          length: text.length >= 8,
        },
      });

      //@ts-ignore
      this.txtInputConfirmNewPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  setNewPassword(): boolean {
    if (
      (this.state.newPassword === null || this.state.newPassword.length === 0) || 
      (this.state.confirmNewPassword === null || this.state.confirmNewPassword.length === 0)
    ) {
      this.showAlert(
        "ERROR",
        "All fields are mandatory."
      );
      return false;
    }

    if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.showAlert("Error", "New password not matching");
      return false;
    }

    if (!(/[A-Z]/.test(this.state.confirmNewPassword))) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordUppercase);
      return false;
    }

    if (!(/[a-z]/.test(this.state.confirmNewPassword))) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordLowercase);
      return false;
    }

    if (!(/\d/.test(this.state.confirmNewPassword))) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordNumber);
      return false;
    }

    if (!(this.state.confirmNewPassword.length >= 8)) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordLength);
      return false;
    }

    this.setState({
      loading: true
    })

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const httpBody = {
      data: {
        password: this.state.confirmNewPassword,
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setNewPasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `passwords?new_password=${this.state.newPassword}&new_password_confirmation=${this.state.confirmNewPassword}&token=${this.state.token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  // Customizable Area End
}
