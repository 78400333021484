import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ValueType } from "react-select";
export interface IFreeTrailSubscriptionResponse {
  status: boolean;
  message: string;
  subscription_details: {
    plan: string;
    billing_amount: string;
    next_bill_date: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  freeTrailSubscriptionStatus: IFreeTrailSubscriptionResponse;
  name: string;
  cardNumber: string;
  expirationDate: string;
  cvv: string;
  country: ValueType<{ label: string; value: string; }, false>;
  zipCode: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FreeTrialController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdfatchFreetrialStatus: string = '';
  apiCallIdProceedPayment: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      freeTrailSubscriptionStatus: {
        status: false,
        message: '',
        subscription_details: {
          plan: '',
          billing_amount: '',
          next_bill_date: '',
        },
      },
      name: "",
      cardNumber: "",
      expirationDate: "",
      cvv: "",
      country: { label: "", value: "" },
      zipCode: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId == this.apiCallIdfatchFreetrialStatus) {
          if (responseJson.data && !errorReponse) {
            this.setState({ freeTrailSubscriptionStatus: responseJson.data });
          }
        }

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.fatchFreetrialStatus();
  }

  txtInputNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtInputNameProps.value = text;
    }
  }

  txtInputCardNumberProps = {
    onChangeText: (text: string) => {
      let formattedText = text.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 '); 

      this.setState({ cardNumber: formattedText })

      //@ts-ignore
      this.txtInputCardNumberProps.value = formattedText;
    }
  }

  txtInputExpirationDateProps = {
    onChangeText: (text: string) => {
      let formattedText = text.replace(/\D/g, '').replace(/(\d{2})(\d{1,2})/g, '$1/$2')
      this.setState({ expirationDate: formattedText });

      //@ts-ignore
      this.txtInputExpirationDateProps.value = formattedText;
    }
  }

  txtInputCvvProps = {
    onChangeText: (text: string) => {
      let formattedText = text.replace(/\D/g, '')
      this.setState({ cvv: formattedText });

      //@ts-ignore
      this.txtInputCvvProps.value = formattedText;
    }
  }

  selectInputCountryProps = {
    onChange: (value: ValueType<{ label: string; value: string; }, false>) => this.setState({ country: value })
  }

  txtInputZipCodeProps = {
    onChangeText: (text: string) => {
      let formattedText = text.replace(/\D/g, '')

      this.setState({ zipCode: formattedText });

      //@ts-ignore
      this.txtInputZipCodeProps.value = formattedText;
    }
  }

  btnProceedPay = {
    onPress: () => this.doProceedPayment()
  }

  doProceedPayment = () => {
    console.log({
      name: this.state.name,
      cardNumber: this.state.cardNumber,
      expirationDate: this.state.expirationDate,
      cvv: this.state.cvv,
      country: this.state.country,
      zipCode: this.state.zipCode
    })
  }

  fatchFreetrialStatus = () => {
    const userToken = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!userToken || !userId) {
      return;
    }

    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.apiCallIdfatchFreetrialStatus = requestMessage.messageId;

    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fatchFreeTrailStatusApiEndPoint}/${userId}` // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) // here add header data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod // api method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  cancelFreeTrialNotificationModal = () => {
    this.setState(prevState => ({
      freeTrailSubscriptionStatus: {
        ...prevState.freeTrailSubscriptionStatus,
        status: false
      }
    }));
  }
  // Customizable Area End
}
