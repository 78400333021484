Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getCategoriesEndPoint = "bx_block_categories/categories";
exports.getApiMethod = "GET";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.apiContentType = "application/json";
exports.getGeofenceAPiEndPoint = "bx_block_geofence4/geofences";
exports.searchingApiEndPoint = "bx_block_advanced_search/search/search_recommendation?search=";
exports.getCityBoundary = "https://nominatim.openstreetmap.org/search?";
exports.exampleAPiMethod = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Maps";
exports.labelBodyText = "Maps Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End